<template>
  <div id="document">
    <div class="document-title">
      <span class="title">
        {{ title }}
      </span>
      <font-awesome-icon class="fa-icon" v-if="webShareApiSupported"
          icon="share-alt"
          @click="shareViaWebShare"
          size="lg"
          @mouseover="['fa-icon']"
      />
      <font-awesome-icon class="fa-icon" v-else icon="copy" @click="triggerCopy" size="lg" @mouseover="['fa-icon']"/>
    </div>
    <div class="document" v-html="document.fullText">
    </div>
  </div>
</template>


<script>
export default {
  name: "DocumentsTable",
  props: {
    document: Object,
    directUrl: String,
    title: String,
  },
  computed: {
    webShareApiSupported() {
      return navigator.share
    }
  },
  methods: {
    shareViaWebShare() {
      navigator.share({
        url: this.directUrl
      })
    },
    triggerCopy() {
      const el = document.createElement('textarea');
      el.value = this.directUrl;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
  }

};
</script>


<style lang="scss" scoped>
.document {
  font-size: 0.8em;
}

.document-title {
  display: flex;
  margin-bottom: 1.2em;
  gap: 1em;
  align-items: center;
}

.title {
  margin-bottom: unset;
}

h2.title {
  display: inline-block !important;
  margin-right: 0.5em !important;
}

.fa-icon:hover {
  color: #9ba0a8;
}
</style>


