<script>
import Armory from "armory-sdk/src/Armory";
import DocumentsTable from "@/components/DocumentsTable.vue";
import DetailedDocument from "@/components/DetailedDocument.vue";

export default {
  name: "App",
  extends: Armory,
  methods: {
    getCustomTemplates() {
      return {};
    },
    getCustomComponents() {
      return {
        DocumentsTable,
        DetailedDocument,
      };
    },
    // eslint-disable-next-line no-unused-vars
    preReceivedSpecificationTrigger(receivedData) {
    },
    postReceivedSpecificationTrigger() {
    }
  }
};
</script>
