Copied code from SK
<template>
  <div id="documents-table">
    <div class="document" v-for="document in documents" :key="document.id">
      <div class="document-title">
        <SubmitButton :text="document.title" :inputId="'document-details'" :nextScreen="document.id" :custom-classes="['document-title', 'document-button']"></SubmitButton>
      </div>
      <div class="document-info">
        <span class="date-from">{{ document.dateFrom }}</span>
        <span class="court">{{ document.court }}</span>
      </div>
      <div class="highlight" v-html="document.fullText" />
    </div>
  </div>
</template>


<script>
import SubmitButton from "armory-sdk/src/components/SubmitButton.vue";

export default {
  name: "DocumentsTable",
  components: {
    SubmitButton
  },
  props: {
    documents: Array,
  },
};
</script>

<style lang="scss" scoped>
#documents-table {
  font-size: 0.8em;
}
.document {
  margin-bottom: 1.5em;
}

::v-deep .document-title {
  color: #c3141b;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  font-weight: bold;
  border: none;
  padding: 0;
  margin: 0;
  height: auto;
  text-wrap: wrap;
  text-align: left;
}
::v-deep .document-button:hover span {
  color: #8d0a0f;
}
::v-deep .document-button:active {
  box-shadow: unset !important;
  border: none !important;
}
.document-info {
  font-weight: bold;
  color: dimgray;
  margin-bottom: 0.5rem;
  margin-top: 0.1rem;
}
.date-from {
  margin-right: 1rem;
}
.highlight {
}
</style>
